import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AttractionsIcon from '@mui/icons-material/Attractions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import ShouldRender from './ShouldRender';
import ToolCard from './ToolCard';
import ImageCard from './ImageCard';
import data from '../data/section4.json';

const StyledTimeline = styled(Timeline)`
    .MuiTimelineItem-root {
        &:before {
            display: none
        }
    }
`;

export default function CustomizedTimeline() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const position = smallScreen ? 'right' : 'alternate';
  return (
      <>
      <Box sx={{ p: 1 }}>
          <Typography variant="h4" sx={{ pt: 2, textAlign: 'center' }} gutterBottom>
            {data.heading}
          </Typography>
          <Typography sx={{ textAlign: 'center', pb: 2 }} gutterBottom>
              {data.subheading}
          </Typography>
        </Box>
        <StyledTimeline
            position={position}
            sx={{
                px: { xs: .5, sm: 2, md: 4, lg: 8, xl: 10 }
            }}
        >
            {data.projects.map((d, i) => {
                if (d?.imageOnly) {
                    return (
                        <TimelineItem key={i}>
                            <ShouldRender if={!smallScreen}>
                                <TimelineOppositeContent
                                    sx={{ m: 'auto 0' }}
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {d.category}
                                </TimelineOppositeContent>
                            </ShouldRender>
                            <TimelineSeparator>
                                <TimelineConnector sx={{ bgcolor: 'skyblue', minHeight: 120 }} />
                                <TimelineDot color="success" variant="outlined">
                                    <AttractionsIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main', minHeight: 120 }} />
                            </TimelineSeparator>
                            <TimelineContent sx={{ textAlign: 'center', alignSelf: 'center' }}>
                                <ImageCard
                                    title={d.alt}
                                    image={d.src}
                                />
                            </TimelineContent>
                        </TimelineItem>
                    );
                }
                return (
                    <TimelineItem key={i}>
                        <ShouldRender if={!smallScreen}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                variant="body2"
                                color="text.secondary"
                            >
                                {d.category}
                            </TimelineOppositeContent>
                        </ShouldRender>
                        <TimelineSeparator>
                            <TimelineConnector sx={{ bgcolor: 'warning.main',  minHeight: 120 }} />
                                <TimelineDot color="grey">
                                    <TimelapseIcon />
                                </TimelineDot>
                            <TimelineConnector sx={{ bgcolor: 'secondary.main',  minHeight: 120 }} />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <ToolCard
                                blue={d?.blueTheme ?? false}
                                heading={d.name || ''}
                                subheading={d.summary || ''}
                                body={d.body || ''}
                                link={d.link || ''}
                                cta={d.cta || ''}
                            />
                        </TimelineContent>
                    </TimelineItem>
                );
            })}
        </StyledTimeline>
    </>
  );
}
