import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

export default function Footer() {
  return (
    <Box sx={{
            m: 6,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
    >
        <Box component="img" src="/orijtech-logo-multi.svg" sx={{ height: 40 }} />
        <Link
            color="inherit"
            href="https://orijtech.com"
            target="_blank"
            sx={{ textDecoration: 'none' }}
        >
            {' © '} Orijtech, Inc.
        </Link>
    </Box>
  );
};
