import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import data from '../data/section1.json';

const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    position: 'sticky',
    top: theme.spacing(.01),
    zIndex: 100,
    flexGrow: 1,
}));

export default function MenuAppBar() {
  return (
    <StyledBox>
        <Box 
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 2,
                bgcolor: 'background.paper',
            }}
        >
            <Box component={Typography} variant="caption" color="text.secondary" sx={{ fontSize: { xs: 10, lg: 16 } }}>
                {data.body}
            </Box>
            <Box
                component="img"
                src={data.logo1.src}
                alt={data.logo1.alt}
                sx={{ height: 36, pr: 1 }}
            />
        </Box>
        <AppBar position="sticky" color="default" elevation={1}>
            <Toolbar>
                <Box
                    component="img"
                    src={data.logo2.src}
                    alt={data.logo2.alt}
                    sx={{ height: 36 }}
                />
            </Toolbar>
        </AppBar>
    </StyledBox>
  );
}
