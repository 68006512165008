import Container from '@mui/material/Container';
import AppBar from './components/AppBar';
import Banner from './components/Banner';
import Timeline from './components/Timeline';
import Sponshorship from './components/Sponsorship';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import Footer from './components/Footer';

import ReactGA from 'react-ga4';

ReactGA.initialize('G-XVMX7FH9MR');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  return (
    <>
    <AppBar />
    <Container
      maxWidth={false}
      sx={{ px: { xs: 0 }, mx: { xs: 0 } }}
    >
      <Banner />
      <Sponshorship />
      <Box sx={{
          bgcolor: grey[100],
          p: { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }
        }}
      >
        <Timeline />
      </Box>
      <Footer />
    </Container>
    </>
  );
}
