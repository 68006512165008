import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

interface Props {
    image: string;
    title: string;
};

export default function ImageCard(props: Props) {
  return (
    <Card>
      <CardMedia
        component="img"
        alt={props.title}
        height="300"
        image={props.image}
      />
    </Card>
  );
}
