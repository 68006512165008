import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import data from '../data/section3.json';

export default function Sponsorship() {
  return (
    <Box sx={{
            p: { xs: 4, sm: 6, md: 10, lg: 12, xl: 16 },
            margin: 'auto',
            bgcolor: '#fafafa',
        }}
    >
      <Grid container>
        <Grid item sm={1} />
        <Grid item sm={10} container>
            <Grid item xs sx={{ textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    {data.heading}
                </Typography>
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    {data.subheading}
                </Typography>
                <Button
                    component="a"
                    href={data.link}
                    target="_blank"
                    sx={{ color: 'darkblue' }}
                >
                    {data.cta}
                </Button>
            </Grid>
        </Grid>
        <Grid item sm={1} />
      </Grid>
    </Box>
  );
}
