import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

interface Props {
    heading: string;
    subheading: string;
    body: string;
    link: string;
    cta: string;
    blue?: boolean;
};

export default function ToolCard(props: Props) {
  const style: {[x: string]: string} = {};
  style['borderRadius'] = '10px';
  if (props.blue) {
      style['background'] = 'linear-gradient(to right bottom, rgb(47, 50, 52), rgb(0, 89, 178) 120%)';
      style['color'] = '#fff';
  }
  return (
    <Card sx={style}>
        <CardHeader
            title={
                <Chip
                    label={props.heading}
                    color={props.blue ? 'warning' : 'success'}
                />
            }
        />
        <CardContent>
            <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ fontWeight: 500 }}
            >
                {props.subheading}
            </Typography>
            <Typography
                variant="body1"
                color={props.blue ? '#fff' : 'text.secondary'}
                gutterBottom
            >
                {props.body}
            </Typography>
            <Button
                size="small"
                variant="outlined"
                color={props.blue ? 'inherit' : 'success' }
                component="a"
                href={props.link}
                target="_blank"
                sx={{marginTop: 2}}
            >
                {props.cta}
            </Button>
        </CardContent>
    </Card>
  );
}
