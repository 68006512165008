import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import data from '../data/section2.json';

export default function Banner() {
    return (
        <Box sx={{
            py: { xs: 6, sm: 8, md: 10, lg: 14, xl: 18 },
            margin: 'auto',
        }}>
            <Grid container spacing={2}>
                <Grid item sm={1} container />
                <Grid item xs={12} sm={6} sx={{ justifyContent: 'center' }} container>
                    <Grid item xs container direction="column" spacing={3} sx={{ alignSelf: 'center' }}>
                        <Grid
                            item
                            xs
                            sx={{
                                textAlign: { xs: 'center', md: 'left' }
                            }}
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: { xs: 40, sm: 44, md: 52, lg: 62, xl: 68 },
                                    fontWeight: 'bold',
                                }}
                            >
                                {data.heading}
                            </Typography>
                            <Typography
                                variant="h6"
                                color="GrayText"
                                sx={{
                                    fontSize: { xs: 14, sm: 16, md: 18, lg: 24 },
                                    mt: 2
                                }}
                                gutterBottom
                            >
                                {data.body}
                            </Typography>
                            <Button
                                component="a"
                                variant="contained"
                                href={data.link}
                                color="primary"
                                size="medium"
                                sx={{ textTransform: 'none', bgcolor: 'black', mt: 2 }}
                            >
                                {data.cta}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={4} container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        component="img"
                        src={data.image}
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            p: 1,
                        }}
                    />
                </Grid>
                <Grid item sm={1} container />
            </Grid>
        </Box>
    );
}
